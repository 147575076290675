const fonts = {
	written: {
		print: `font-family: "Permanent Marker", cursive;`,
		cursive: `font-family: "Homemade Apple", system-ui;`,
	},
	monospace: `font-family: "VT323", monospace;`,
	encoded: `font-family: "Libre Barcode 39 Text", system-ui;`,
};

export default fonts;
