import styled from "styled-components";

export const Binding = styled.div`
	background-color: black;
	width: 2.5%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
`;

export const AppContainer = styled.div`
	text-align: center;
	margin-left: 2.5%;
	position: relative;
	z-index: 2;
`;
