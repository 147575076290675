function Extra() {
	return (
		<>
			<h3>Binary Convertor table</h3>
			<h3>Computer Metric table</h3>
		</>
	);
}

export default Extra;
