const QuoteData = [
	{
		quote: `My mama said, "Marry a rich man." And I was like, "Mama, I am that rich man"`,
		author: "Destiny Rogers",
	},
	{
		quote: "Desire. Direction. Discipline.",
		author: "Sabrina Terrell",
	},
	{
		quote: "I don't see nobody but me, who I'ma lose to?",
		author: "Lil Baby",
	},
	{
		quote: "Day by Day in Every Way, I am Getting Better & Better.",
		author: null,
	},
	{
		quote: "She's a lil different, but she's solid",
		author: null,
	},
	{
		quote: "Find the Funny",
		author: "My Mommy",
	},
	{
		quote: "Note to Self: I'm going to make you so proud!",
		author: null,
	},
	{
		quote: "Don't be pushed by your problems. Be led by your dreams.",
		author: null,
	},
	{
		quote: "WETFIT",
		author: "B. Thump",
	},
	{
		quote: "Prior Proper Planning Prevents Poor Performance",
		author: "My Papa",
	},
	{
		quote:
			"Live less out of habit and more out of intent. \nYou take your life in your own hands, and what happens? \nA terrible thing, no one to blame.",
		author: null,
	},
	{
		quote: "You Got This",
		author: "My Bestie",
	},
	{
		quote:
			"We support you 100% through anything but you have to make the first move.",
		author: "My Granny",
	},
	{
		quote: "A small piece of leather, but well put together",
		author: "Mom, My GreatGrandma",
	},
	{
		quote: "If it's one thing you taught me, it's how to fight.",
		author: "Jhene Aiko",
	},
	{
		quote: "My mom clapped so loud for me that I never noticed who didn't.",
		author: null,
	},
];

export default QuoteData;
