function Portfolio() {
	return (
		<>
			<h2>Skills Placeholder</h2>
			<h2>Role Placeholder</h2>
			<h2>Projects Placeholder</h2>
		</>
	);
}

export default Portfolio;
